<template>
	<div class="cart" v-loading="loading">
		<template v-if="cartList.length || invalidGoods.length">
			<nav>
				<li>
					<el-checkbox v-model="checkAll" @change="allElection"></el-checkbox>
				</li>
				<li>Product info</li>
				<li>price</li>
				<li>quantity</li>
				<li>Subtotal</li>
				<li>operate</li>
			</nav>
			<div class="list" v-for="(siteItem, siteIndex) in cartList" :key="siteIndex">
				<div class="item">
					<div class="head">
						<el-checkbox v-model="siteItem.checked" @change="siteAllElection(siteIndex)"></el-checkbox>
						<span style="margin: 0 10px;">{{ siteItem.siteName }}</span>
						<!-- <el-tag size="small" v-if="siteItem.cartList[0].is_own == 1">自营</el-tag> -->
					</div>
					<ul v-for="(item, cartIndex) in siteItem.cartList" :key="cartIndex">
						<li>
							<el-checkbox v-model="item.checked"
								@change="singleElection(siteIndex, cartIndex)"></el-checkbox>
						</li>
						<li class="goods-info-wrap" @click="$router.pushToTab({ path: `/sku-${item.sku_id}` })">
							<div class="img-wrap"><img class="img-thumbnail" :src="$img(item.sku_image, { size: 'mid' })"
									@error="imageError(siteIndex, cartIndex)" /></div>
							<div class="info-wrap">
								<h5>{{ item.sku_name }}</h5>
								<template v-if="item.sku_spec_format">
									<span v-for="(x, i) in item.sku_spec_format" :key="i">
										{{ x.spec_name }}：{{ x.spec_value_name }}
										{{ i < item.sku_spec_format.length - 1 ? '；' : '' }}
									</span>
								</template>
							</div>
						</li>
						<li>
							<span>PH{{ item.discount_price }}</span>
						</li>
						<li>
							<el-input-number v-model="item.num" :step="modifyNum" size="mini" :min="1" :max="item.stock"
								@change="cartNumChange($event, { siteIndex, cartIndex })"></el-input-number>
						</li>
						<li>
							<strong class="subtotal ns-text-color" v-if="item.discount_price * item.num">PH{{
								(item.discount_price * item.num).toFixed(2)
							}}</strong>
							<strong class="subtotal ns-text-color" v-else>$0</strong>
						</li>
						<li>
							<el-button type="text" @click="deleteCart(siteIndex, cartIndex)">Delete</el-button>
						</li>
					</ul>
				</div>
			</div>

			<div class="lose-list" v-if="invalidGoods.length">
				<div class="head">
					<span class="ns-text-color">{{ invalidGoods.length }}</span>
					Invalid products
				</div>
				<ul v-for="(goodsItem, goodsIndex) in invalidGoods" :key="goodsIndex">
					<li>
						<el-tag size="small" type="info">Invalid</el-tag>
					</li>
					<li class="goods-info-wrap">
						<div class="img-wrap"><img class="img-thumbnail" :src="$img(goodsItem.sku_image, { size: 'mid' })"
								@error="imageErrorInvalid(goodsIndex)" /></div>
						<div class="info-wrap">
							<h5>{{ goodsItem.sku_name }}</h5>
							<template v-if="goodsItem.sku_spec_format">
								<span v-for="(x, i) in goodsItem.sku_spec_format" :key="i">
									{{ x.spec_name }}：{{ x.spec_value_name }}
									{{ i < goodsItem.sku_spec_format.length - 1 ? '；' : '' }}
								</span>
							</template>
						</div>
					</li>
					<li>
						<span>PH{{ goodsItem.discount_price }}</span>
					</li>
					<li>{{ goodsItem.num }}</li>
					<li>
						<strong class="subtotal">PH{{ goodsItem.discount_price * goodsItem.num }}</strong>
					</li>
				</ul>
			</div>

			<footer>
				<el-checkbox v-model="checkAll" @change="allElection">select all</el-checkbox>
				<ul class="operation">
					<li>
						<el-button type="text" @click="deleteCartSelected">Delete</el-button>
					</li>
					<li>
						<el-button type="text" @click="clearInvalidGoods" v-if="invalidGoods.length != 0">Clear invalid
							items</el-button>
					</li>
				</ul>
				<div class="sum-wrap">
					<div class="selected-sum">
						<em class="total-count">{{ totalCount }}</em>
						<span>selected products</span>
					</div>
					<div class="price-wrap">
						<span>Total (excluding shipping costs)：</span>
						<strong class="ns-text-color">PH{{ totalPrice }}</strong>
					</div>

					<el-button type="primary" v-if="totalCount != 0" @click="settlement">settle accounts</el-button>
					<el-button type="info" v-else disabled @click="settlement">settle accounts</el-button>
				</div>
			</footer>
		</template>
		<div class="empty-wrap" v-else-if="!loading && (!cartList.length || !invalidGoods.length)">
			<router-link to="/">Your shopping cart is empty, go and see the products you are interested in</router-link>
		</div>
	</div>
</template>

<script>
import cart from './cart';
export default {
	name: 'cart',
	mixins: [cart]
};
</script>
<style lang="scss" scoped>
@import './cart.scss';
</style>
